<template>
    <Complaint />
</template>

<script setup>
import Complaint from "@/complaint-components/Complaint.vue";
</script>

<script>
export default {
    path: "/anonymous-report",
    name: "anonymous-report",
    icon: "mdi-home",
    sort: 100,
};
</script>

<style scoped>
#home {
    overflow-y: scroll;
    height: calc(100vh - 64px);
}
</style>
