<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" sm="8" md="6">
                <v-card>
                    <v-card-title class="headline">##&en Complaint Report ##&hu Panasz bejegyzés##</v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-textarea
                                v-model="data.report"
                                label="##&en Your complaint description ##&hu A panasz részletes leírása ##"
                                persistent-hint
                                auto-grow
                                autofocus
                                rows="4"
                              	:hint="data.report.length > 0 && data.report.length < 20 ? '##&en Please continue ,... ##&hu Kérem folytassa, ... ##': '...'"
                              	:disabled="data.ok"
                            ></v-textarea>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" variant="elevated" class="ma-1" @click.native="submitReport" min-width="200" :disabled="data.report.length < 20 || data.ok">
                            <p style="text-transform: none"><v-icon class="pr-3">mdi-send</v-icon>##&en Submit ##&hu Küldés ##</p></v-btn
                        >
                    </v-card-actions>
                    <v-card-text>
                        <v-alert v-if="data.message"
                            type="success"
                            :title="data.message"
                            text=""
                        ></v-alert>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup>
import { ref, onMounted, reactive } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const data = reactive({
    ok: false,
    report: "",
    message: null,
});

async function submitReport() {
  	data.message = "...";
  	data.ok = true;
    const response = await fetch("/post-complaint.json", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            report: data.report,
        }),
    });
    if (!response.ok) return console.log("[post] There was an error. ", response);

    const res = await response.json();
  Ł(response, res);

    Object.assign(data, res);
}
</script>
