<template>
    <div :class="{ valid: isValid() === true, invalid: isValid() !== true, readonly: format.readonly === true }">
        <component
            :is="format.is"
            :bond="props.bond"
            v-bind="data.format"
            v-model="data.value"
            v-model:valid="data.valid"
            @input="updateHandler"
            @update="updateHandler"
            @update:modelValue="updateHandler"
            @update:valid="validHandler"
            class="pa-3"
            :ref="getRef('component')"
        >
        </component>
    </div>
    <!-- <v-alert v-if="isValid() !== true" color="warning" max-height="1"></v-alert> -->
    <!-- 
  	component {{ format }} value: {{ modelValue }} data: {{ data }}
    end -->
</template>

<script setup>
// this components goal is to merge vuetify and vuetiform components
import { ref, reactive, watch, nextTick, toRaw, onMounted } from "vue";
import { structuredClone } from "../../helper-functions.mjs";
function clone(p) {
    return structuredClone(toRaw(p));
}

const props = defineProps(["bond", "format", "modelValue", "disabled", "readonly"]);
// we should be reactive
// model
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);
const data = reactive({ format: clone(props.format), value: clone(props.modelValue), valid: true });

// :ref="getRef('component')"
const refs = reactive({
    component: null,
});

function getRef(key) {
    return (el) => (refs[key] = el);
}

async function refresh() {
    Object.assign(data, { format: clone(props.format), value: clone(props.modelValue) });
    await nextTick();
    if (refs.component) if (refs.component.refresh) await refs.component.refresh();
    validHandler();
}

//defineExpose({ refresh });

if (!props.format) {
    console.log(toRaw(props));
    console.error("%cError: Vuetiform component must have a format !!", "color: red;");
}
// allow manipulationg the format
const format = clone(props.format);
if (props.disabled) data.format.disabled = true;
if (props.readonly) data.format.readonly = true;

// process vuetify validators
import validators from "../../validators.mjs";
{
    const format = data.format;
    if (format.validators || format.regex) {
        format.rules = [];
        let list = format.validators || [];
        if (typeof format.validators === "string") list = format.validators.split("|");
        if (format.mandatory === true) list.push("validateMandatoryField");
        for (const i of [...new Set(list)])
            format.rules.push((input) => {
                const r = validators[i](input);
                if (r === true) return true;
                if (r === false) return "Invalid by " + i;
                return r;
            });
        if (format.regex)
            format.rules.push((str) => {
                const re = new RegExp(format.regex, "i");
                if (re.test(str) == false) return "##&en Regex check failed. ##&hu regex ellenőrzés hibajelzés ##";
                return true;
            });
    }
}
const isValid = () => {
    const format = data.format;
    if (data.valid !== true) return data.valid;
    if (format.readonly === true) return true;
    if (format.mandatory === true) if (data.value === undefined || data.value === null) return (format.label || "") + " ##&en Mandatory field ##&hu Kötelező mező ##";
    if (!format.validators) return true;
    let list = format.validators || [];
    if (typeof format.validators === "string") list = format.validators.split("|");
    for (const i of list) {
        const r = validators[i](data.value);
        if (r === false) return "Invalid from " + i;
        if (r !== true) return r;
    }
    return true;
};

defineExpose({ refresh, isValid });

// we also need to keep track of validators of poliform modules
//const valid = ref(isValid() === true);
const type = (data.format._type || "String").toLowerCase();
function numeric(i) {
    if (i === undefined) return null;
    const int = parseInt(i.toString().replace(/\s+/g, ""), 10);
    const num = Number(int);
    if (isNaN(num)) return null;
    return num;
}

let collect = false;
async function updateHandler(d, ...a) {
    if (collect) return;
    collect = true;
    await nextTick();
    collect = false;

    let datum = clone(data.value);
    if (type === "Number") datum = numeric(datum);

    emit("update", datum, ...a);
    emit("update:modelValue", datum);
    validHandler();
}

let lastValidUpdate = null;
function validHandler() {
    const valid = isValid();
    //if (lastValidUpdate === valid) return;
    emit("update:valid", valid);
    lastValidUpdate = valid;
}

// default value
if (format.default !== undefined) {
    if (data.value === undefined) {
        data.value = format.default;
        updateHandler();
    }
}

onMounted(async () => {
    validHandler();
});

//updateHandler();
</script>

<script>
import * as vuetify from "vuetify/components";
import vuetiform from "../../vuetiform-components.mjs";
// allow recursive
//console.log(vuetify, vuetiform);
export default {
    inheritAttrs: false,
    components: { ...vuetify, ...vuetiform },
    name: "vuetiform-component",
};
</script>
<style scoped>
.valid {
    border-left: 3px solid rgba(0, 0, 0, 0);
}
.invalid {
    background-color: rgba(128, 0, 0, 0.02);
    border-left: 3px solid red;
}
.readonly {
    background-color: rgba(0, 0, 128, 0.06);
    border-left: 3px solid blue;
}
</style>
