<template>
    <v-btn
        v-if="!data.readonly"
        block
        size="x-large"
        class="pa-4"
        @click="
            data.sidenote = { owner: true };
            data.dialog = true;
        "
        prepend-icon="mdi-plus"
        >##&en Sidenote ##&hu Oldaljegyzet ##</v-btn
    >
    <v-card v-for="(sidenote, i) in data.sidenotes" :key="i" @click="open(sidenote)" @contextmenu="rightclick($event, sidenote)" class="">
        <v-card-title>{{ sidenote.title }}</v-card-title>
        <v-card-subtitle><v-icon v-if="sidenote.url" icon="mdi-link" /><v-icon v-else icon="mdi-link-off" /> {{ sidenote.name }}</v-card-subtitle>
        <v-card-text class="pt-2" style="padding: 0px !important"><v-textarea readonly v-model="sidenote.text" auto-grow rows="1" variant="solo" flat /> </v-card-text>
    </v-card>

    <v-dialog v-model="data.dialog" max-height="500" max-width="600">
        <v-card v-if="data.sidenote.owner">
            <v-card-title
                >@ {{ data.sidenote.name }}
                {{ data.sidenote.active ? "##&en Page sidenote ##&hu Megosztott Oldaljegyzet ##" : "##&en User Sidenote ##&hu Felhasználói Oldaljegyzet ##" }}</v-card-title
            >
            <v-card-text>
                <v-text-field v-model="data.sidenote.title" autofocus label="##&en Sidenote Title ##&hu Oldaljegyzet címsor ##" @keyup.enter="save()" />
                <v-textarea v-model="data.sidenote.text" label="##&en Sidenote Text ##&hu Oldaljegyzet szövege ##" auto-grow rows="1" />
                <v-text-field v-model="data.sidenote.url" label="##&en Hyperlink URL ##&hu URL hivatkozás ##" @keyup.enter="save()" />
            </v-card-text>
            <v-card-actions>
                <v-btn :disabled="!data.sidenote._id" icon size="large" @click.native="remove()" class="bg-primary">
                    <v-icon>mdi-trash-can</v-icon>
                </v-btn>
                <v-spacer />
                <v-btn :disabled="(data.sidenote.title || '').length < 2 || !isURL(data.sidenote.url)" icon size="large" @click.native="save()" class="bg-primary">
                    <v-icon>mdi-content-save</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-else>
            <v-card-title
                >$ {{ data.sidenote.name }}
                {{ data.sidenote.active ? "##&en Page sidenote ##&hu Megosztott Oldaljegyzet ##" : "##&en User Sidenote ##&hu Felhasználói Oldaljegyzet ##" }}</v-card-title
            >
            <v-card-text>
                <v-text-field readonly v-model="data.sidenote.title" label="##&en Sidenote Title ##&hu Oldaljegyzet címsor ##" variant="solo" flat />
                <v-textarea
                    readonly
                    v-if="data.sidenote.text"
                    v-model="data.sidenote.text"
                    label="##&en Sidenote Text ##&hu Oldaljegyzet szövege ##"
                    auto-grow
                    rows="1"
                    variant="solo"
                    flat
                />
                <v-text-field readonly v-if="data.sidenote.url" v-model="data.sidenote.url" label="##&en Hyperlink URL ##&hu URL hivatkozás ##" variant="solo" flat />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn :disabled="(data.sidenote.title || '').length < 2 || !isURL(data.sidenote.url)" icon size="large" @click.native="data.dialog = false" class="bg-primary">
                    <v-icon>mdi-check-circle</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script setup>
import { onMounted, onUnmounted, reactive } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

function isURL(str) {
    if (str === null) return true;
    const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name and extension
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return !!pattern.test(str);
}

const data = reactive({
    dialog: false,
    sidenotes: [],
    sidenote: {},
    readonly: true,
});

const save = () => {
    $socket.emit("sidenotes:Sidenote:save", { document: data.sidenote, route: route.fullPath }, () => {
        data.sidenote = {};
        data.dialog = false;
    });
};
const remove = () => {
    $socket.emit("sidenotes:Sidenote:remove", { id: data.sidenote._id, route: route.fullPath }, () => {
        data.sidenote = {};
        data.dialog = false;
    });
};
const open = (sidenote) => {
    if (sidenote.url) return window.open(sidenote.url, "_blank");
    Object.assign(data, { sidenote });
    data.dialog = true;
};

const rightclick = (e, sidenote) => {
    e.preventDefault();
    Object.assign(data, { sidenote });
    data.dialog = true;
};

const load = () => {
    $socket.emit("sidenotes:Sidenote:load", { route: route.fullPath }, (o) => Object.assign(data, o));
};

onMounted(async () => {
    await $connection();
    load();

    $socket.on("sidenotes:Sidenotes:update", (o) => {
        Object.assign(data, o);
    });

    router.afterEach((to, from) => {
        $socket.emit("sidenotes:Sidenote:load", { route: to.fullPath }, (o) => Object.assign(data, o));
    });
});
onUnmounted(async () => {
    $socket.removeListener("sidenotes:Sidenotes:update");
});

//*/
</script>
<style scoped></style>
