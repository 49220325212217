// /srv/codepad-project/@database-modules/dataview/vue3/vuetiform-components/DatabaseCollectionSelector.vue
import DatabaseCollectionSelector from './src/vuetiform-components/DatabaseCollectionSelector.vue';

// /srv/codepad-project/@database-modules/dataview/vue3/vuetiform-components/DatabaseDocumentSelector.vue
import DatabaseDocumentSelector from './src/vuetiform-components/DatabaseDocumentSelector.vue';

// /srv/codepad-project/@vue3-modules/vuetifiles/vue3/vuetiform-components/FileDisplay.vue
import FileDisplay from './src/vuetiform-components/FileDisplay.vue';

// /srv/codepad-project/@vue3-modules/vuetifiles/vue3/vuetiform-components/VuetiformFile.vue
import VuetiformFile from './src/vuetiform-components/VuetiformFile.vue';

// /srv/codepad-project/@vue3-modules/vuetifiles/vue3/vuetiform-components/VuetiformFilelist.vue
import VuetiformFilelist from './src/vuetiform-components/VuetiformFilelist.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/HashtagsCombobox.vue
import HashtagsCombobox from './src/vuetiform-components/HashtagsCombobox.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/MeasurementTextfield.vue
import MeasurementTextfield from './src/vuetiform-components/MeasurementTextfield.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformArray.vue
import VuetiformArray from './src/vuetiform-components/VuetiformArray.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformDate.vue
import VuetiformDate from './src/vuetiform-components/VuetiformDate.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformDuration.vue
import VuetiformDuration from './src/vuetiform-components/VuetiformDuration.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformDynaform.vue
import VuetiformDynaform from './src/vuetiform-components/VuetiformDynaform.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformList.vue
import VuetiformList from './src/vuetiform-components/VuetiformList.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformObject.vue
import VuetiformObject from './src/vuetiform-components/VuetiformObject.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformPoliform.vue
import VuetiformPoliform from './src/vuetiform-components/VuetiformPoliform.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformPretext.vue
import VuetiformPretext from './src/vuetiform-components/VuetiformPretext.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformServerform.vue
import VuetiformServerform from './src/vuetiform-components/VuetiformServerform.vue';

// /srv/codepad-project/@vue3-modules/vuetiform/vue3/vuetiform-components/VuetiformText.vue
import VuetiformText from './src/vuetiform-components/VuetiformText.vue';

export default { DatabaseCollectionSelector, DatabaseDocumentSelector, FileDisplay, VuetiformFile, VuetiformFilelist, HashtagsCombobox, MeasurementTextfield, VuetiformArray, VuetiformDate, VuetiformDuration, VuetiformDynaform, VuetiformList, VuetiformObject, VuetiformPoliform, VuetiformPretext, VuetiformServerform, VuetiformText };