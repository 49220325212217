<template>
    <v-combobox
        prepend-icon="mdi-pound"
        multiple
        chips
        :items="data.items"
        v-model="data.hashtags"
        rounded
        clearable
        variant="solo"
        closable-chips
        :rules="tagrules"
        @update:modelValue="tagupdate"
        :disabled="props.disabled"
        hint="#hashtags"
        :flat="props.flat"
        :ref="getRef('component')"
    />
</template>
<script setup>
import { ref, reactive, watch, nextTick } from "vue";
import { structuredClone as clone } from "../../helper-functions.mjs";

const emit = defineEmits(["update:modelValue", "update:valid", "update"]);
const props = defineProps(["modelValue", "disabled", "items", "flat"]);

const data = reactive({
    items: clone(props.items || []),
    hashtags: clone(props.modelValue || []),
});

const refs = reactive({
    component: null,
});

function getRef(key) {
    return (el) => (refs[key] = el);
}

async function refresh() {
    Object.assign(data, { hashtags: clone(props.modelValue || []) });
    await nextTick();
    if (refs.component.refresh) await refs.component.refresh();
}

defineExpose({ refresh });

const isValid = (e) => {
    if (e)
        return (
            e
                .map((str) => {
                    return /^#[a-z_0-9]+$/.test(str) || "##&en Invalid tag: ##&hu Érvénytelen tag: ##" + str;
                })
                .filter((b) => b !== true)[0] || true
        );
    else return true;
};
const tagrules = [isValid];

const updateHandler = async () => {
    await nextTick();
    const datum = clone(data.hashtags);
    emit("update", datum);
    emit("update:modelValue", datum);
    emit("update:valid", isValid());
};

function handlePunctuation(str) {
    str = str.toLowerCase();
    const fr = "áäâàãåčçćďéěëèêẽĕȇíìîïňñóöőòôõøðřŕšťúůüűùûýÿžþÞĐđßÆa·/_,:;";
    const to = "aaaaaacccdeeeeeeeeiiiinnoooooooorrstuuuuuuyyzbBDdBAa______";
    for (let i = 0, l = fr.length; i < l; i++) {
        str = str.replace(new RegExp(fr.charAt(i), "g"), to.charAt(i));
    }
    return "#" + str.replace(/[^a-z_0-9]/g, "");
}

const tagupdate = (arr) => {
    data.hashtags = arr
        .map((e) => e.split(" "))
        .flat()
        .map(handlePunctuation)
        .filter((e) => e.length > 2);
    updateHandler();
};
</script>
