<template>
    <v-app>
        <v-main>
            <router-view></router-view>
        </v-main>
    </v-app>
</template>
<script>
</script>

<style scoped>
html {
    overflow: hidden !important;
}
</style>
