<template>
    <v-list-item v-for="v in data.pages" :prepend-icon="v.icon" :title="v.name" @click="router.push(v.link)"></v-list-item>
</template>

<script setup>
import { onMounted, reactive } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

import { useSocketdataStore } from "@/store/socketdataStore.mjs";
const socket = useSocketdataStore();

const data = reactive({
    pages: [],
});

const init = () => {
    $socket.emit("navigation:Navigation:init", {}, (o) => {
        Object.assign(data, o);
    });
};

onMounted(async () => {
    await $connection();
    init();
});
</script>
