<template>
    <v-card-actions class="d-flex justify-center" style="height: 80px">
        <GoogleLogin :callback="callback" prompt :autoLogin="autologin" />
    </v-card-actions>
</template>
<script setup>
import { decodeCredential } from "vue3-google-login";

import { useSessionStore } from "@/store/sessionStore.mjs";
const sessionStore = useSessionStore();

const props = defineProps(["autologin"]);
const autologin = props.autlogoin || false;

const callback = async (response) => {
    const post_response = await fetch("/post-google-login.json", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(response),
    });

    if (!post_response.ok) return console.log("[post] There was an error. ", post_response);

    const res = await post_response.json();

    // serious error can drop an alert
    if (res.alert) return alert(res.alert);

    if (res.login) {
        await sessionStore.resetSession();
        $socket.open();
        await $connection();
    }
};
</script>
