<template>
    <v-card v-if="data.error"><v-alert type="error" :title="data.error" icon="mdi-alert-circle" variant="tonal"></v-alert></v-card>
    <v-card v-else v-resize="onResize" class="pa-4 scrollable overflow-auto">
        <v-card-item class="pa-10">
            <v-container fluid>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="8">
                        <document-info :database="database" :collection="collection" :id="id" :data="data" />
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                        <v-sheet class="flex-fill pa-2">
                            <hashtags-combobox v-if="data.hashtags" v-model="data.document._hashtags" :items="data.hashtags" class="ma-2" />
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-item>
        <v-container fluid color="rgba(0,0,0,0)">
            <v-btn
                :disabled="!data.host || data.host === hostname"
                :color="!data.host || data.host === hostname ? 'gray' : 'primary'"
                @click="openHostDocument()"
                color="primary"
                variant="elevated"
                class="ma-1"
                min-width="200"
            >
                <p style="text-transform: none"><v-icon class="pr-3">mdi-database</v-icon>Server</p>
            </v-btn>
            <v-btn @click="openCollection()" color="primary" variant="elevated" min-width="200" class="ma-1">
                <p style="text-transform: none"><v-icon class="pr-3">mdi-database</v-icon>##&en Collection ##&hu Kollekció ##</p>
            </v-btn>
            <v-btn
                @click.native="saveDocument()"
                :disabled="data.valid !== true || data.document._readonly === true"
                :color="data.valid !== true || data.document._readonly === true ? 'gray' : 'primary'"
                variant="elevated"
                class="ma-1"
                min-width="200"
            >
                <p style="text-transform: none"><v-icon class="pr-3">mdi-database-check</v-icon>##&en Save ##&hu Mentés ##</p>
            </v-btn>
            <v-btn
                @click="removeDocument()"
                :disabled="data.readonly || data.document._readonly /*|| !data.document._createdAt*/"
                :color="getRemoveColor(data.readonly || data.document._readonly)"
                variant="elevated"
                class="ma-1"
                min-width="200"
            >
                <p style="text-transform: none"><v-icon class="pr-3">mdi-delete</v-icon>##&en Delete ##&hu Törlés ##</p>
            </v-btn>
            <v-btn @click.native="openDocument(data.prev)" :disabled="!data.prev" :color="!data.prev ? 'gray' : 'primary'" variant="elevated" class="ma-1" min-width="200">
                <p style="text-transform: none"><v-icon class="pr-3">mdi-chevron-left</v-icon>##&en Previous ##&hu Előző ##</p>
            </v-btn>
            <v-btn @click.native="openDocument(data.next)" :disabled="!data.next" :color="!data.next ? 'gray' : 'primary'" variant="elevated" class="ma-1" min-width="200">
                <p style="text-transform: none"><v-icon class="pr-3">mdi-chevron-right</v-icon>##&en Next ##&hu Következő ##</p>
            </v-btn>
            <DocumentVersions
                v-if="data.versions.length > 1"
                :bond="props.bond"
                :database="database"
                :collection="collection"
                :id="id"
                :version="data.version"
                :versions="data.versions"
                :key="data.version"
            />
        </v-container>
        <v-card-text>
            <vuetiform-component
                :format="{ is: 'vuetiform-poliform', format: data.format }"
                :bond="props.bond"
                v-model="data.document"
                v-model:valid="data.valid"
                :key="data.document._id"
                :disabled="data.readonly || data.document._readonly"
            />
        </v-card-text>
    </v-card>
    <div>
        <v-btn flat icon="mdi-triangle-small-down" size="small" @click.native="data.debug = !data.debug" style="background-color: transparent" />
        <pre v-if="data.debug">{{ data }}</pre>
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";
const props = defineProps(["bond", "database", "collection", "id", "version", "redirecting", "format"]);

const database = props.database;
const collection = props.collection;
const id = props.id;
const version = props.version;

const hostname = (ß.HOSTNAME || "localhost").split(".")[0];

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

import DocumentInfo from "@/database-components/DocumentInfo.vue";
import DocumentVersions from "@/database-components/DocumentVersions.vue";

import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
import HashtagsCombobox from "@/vuetiform-components/HashtagsCombobox.vue";

const openDocument = (id) => {
    router.push("/document/" + database + "/" + collection + "/" + id);
};

const openCollection = () => {
    router.push("/database/" + database + "/" + collection);
};
const openHostDocument = () => {
    return (window.location.href = "https://" + data.host + "/document/" + database + "/" + collection + "/" + data.document._id);
};

const data = reactive({
    database,
    collection,
    pattern: null,
    document: {},
    format: props.format || {},
    valid: true,
    readonly: true,
    host: null,
    debug: false,
    createdBy: null,
    updatedBy: null,
    hashtags: null,
    error: null,
    prev: null,
    next: null,
    removal: false,
    versions: [],
    version: null,
});

const saveDocument = () => {
    const document = { _id: id, ...data.document };
    $socket.emit("dataview:DocumentView:saveDocument", { database, collection, document }, ({ uri }) => {
        if (uri) return router.push(uri);
        if (props.redirecting) router.push("/database/" + database + "/" + collection);
    });
};

const getRemoveColor = (disabled) => {
    if (disabled) return "gray";
    if (data.removal) return "red";
    return "primary";
};

const removeDocument = () => {
    if (!data.removal) {
        data.removal = true;
        setTimeout(() => {
            data.removal = false;
        }, 2000);
        return;
    }
    const document = data.document;
    $socket.emit("dataview:DocumentView:removeDocument", { database, collection, document }, ({ uri }) => {
        if (uri) return router.push(uri);
        if (props.redirecting) router.push("/database/" + database + "/" + collection);
    });
};

const resizedHeight = ref(0);
const onResize = () => {
    //resizedHeight.value = window.innerHeight - 10;
};

const update = () => {
    $socket.emit("dataview:DocumentView:update", { database, collection, id, version }, (o) => {
        Object.assign(data, o);
    });
};

onMounted(async () => {
    await $connection();
    update();
});
</script>

<style scoped>
.pointer {
    cursor: pointer;
}
/* Scrollable in a dialog*/
.scrollable {
    /*overflow-y: scroll;*/
    height: 100%;
}
</style>
