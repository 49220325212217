<template>
    <v-app-bar color="primary" dark>
        <LanguageButton />
        <v-spacer></v-spacer>
        <v-btn text white @click="data.loginDialog = true">##&en Login ##&hu Bejelentkezés ##</v-btn>
    </v-app-bar>

    <LoginDialog v-model="data.loginDialog" @update="data.loginDialog = false" />

    <v-main>
        <v-container>
            <v-row align="center" justify="center" class="text-center">
                <v-col cols="12">
                    <h1>##&en Welcome on our complaint managment portal ##&hu Üdvözöljük a Panaszkezelő Portálon! ##</h1>
                    <p class="subtitle-1">##&en Fail a complaint, manage complaints ##&hu Panaszbejelentés, panaszkezelés. ##</p>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="4">
                    <v-card class="pa-5" outlined tile height="300">
                        <v-card-title class="justify-center">
                            <v-icon large color="primary">mdi-account-group</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <h3 class="pb-2">##&en For Our Team Members ##&hu Munkatársainknak ##</h3>
                            <p>
                                ##&en Your insights are invaluable. Share your observations and help us improve our workplace together. ##&hu Az Ön véleménye fontos számunkra.
                                Ossza meg észrevételeit, és segítsen nekünk együtt fejleszteni munkahelyünket. ##
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card class="pa-5" outlined tile height="300">
                        <v-card-title class="justify-center">
                            <v-icon large color="primary">mdi-chart-bubble</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <h3 class="pb-2">##&en For Our Partners ##&hu Partnereinknek ##</h3>
                            <p>
                                ##&en Your feedback is essential to our mutual growth. Share your experiences and suggestions, and let's succeed together. ##&hu A visszajelzésük
                                létfontosságú a közös fejlődésünk számára. Osszák meg tapasztalataikat és javaslataikat, és együtt érjünk el sikereket. ##
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card class="pa-5" outlined tile height="300">
                        <v-card-title class="justify-center">
                            <v-icon large color="primary">mdi-shield-check</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <h3 class="pb-2">##&en Secure ##&hu Biztonságos ##</h3>
                            <p>
                                ##&en We keep you and your submissions secure, in order to protect your data and your privacy. ##&hu Adatvédelmi normák betartása ön, és a bizalmas
                                információi védelme érdekében. ##
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="my-12">
            <v-row justify="center">
                <v-col cols="12" md="6" class="text-center">
                    <h2 class="headline">##&en Let's Get Started! ##&hu Kezdjünk! ##</h2>
                    <p>##&en Choose your way to report a complaint. ##&hu Válassza ki, hogyan szeretne panaszt bejelenteni. ##</p>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" md="4">
                    <v-btn block color="secondary" @click="data.loginDialog = true">##&en Login as User ##&hu Bejelentkezés Felhasználóként ##</v-btn>
                </v-col>
                <v-col cols="12" md="4">
                    <v-btn block color="secondary" @click="router.push('/anonymous-report')">##&en Continue Anonymously ##&hu Folytatás Anonimként ##</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-main>

    <v-footer color="primary" app inset>
        <pre class="white--text text-caption">&copy; 2024 Panaszkezelő Portál. D250 Systems Kft. Minden jog fenntartva. GDPR LINK</pre>
    </v-footer>
</template>

<script setup>
import { ref, onMounted, reactive } from "vue";

import LoginDialog from "@/LoginDialog.vue";
import LanguageButton from "@/multilanguage-components/LanguageButton.vue";

const data = reactive({
    loginDialog: false,
});

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
  
</script>

<style scoped>
.subtitle-1 {
    font-size: 1rem;
    margin: 1rem 0;
}

.pa-5 {
    padding: 3rem !important;
}
</style>
