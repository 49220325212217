<template>
    <v-card class="ma-3">
        <v-toolbar density="compact">
            <v-btn icon @click="add()" :disabled="props.disabled">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn icon @click="remove()" :disabled="props.disabled || data.panel === -1">
                <v-icon>mdi-minus</v-icon>
            </v-btn>
            <v-btn icon @click="moveUp()" :disabled="props.disabled || data.panel === -1">
                <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
            <v-btn icon @click="moveDown()" :disabled="props.disabled || data.panel === -1">
                <v-icon>mdi-arrow-down</v-icon>
            </v-btn>

            <v-spacer></v-spacer>
            <pre>{{ props.label }} {{ data.panel > -1 ? "#" + (1 + data.panel) : "" }}</pre>
            <v-spacer></v-spacer>
            <v-btn icon @click="close()" :disabled="props.disabled">
                <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
        </v-toolbar>
        <v-sheet v-for="(e, i) in array" :class="{ select: data.panel === i }" :key="data.key + '-' + e.key + '-' + i">
            <div class="font-weight-bold pa-1 ma-1 pointer" :class="{ alert: e.valid !== true }" @click="select(i)">
                <pre>#{{ i + 1 }} {{ e.value[props.namefield || "name"] }} {{ e.valid === true ? "" : " << " + e.valid }}</pre>
            </div>
            <div class="pa-1" :class="{ hidden: data.panel !== i }">
                <vuetiform-component
                    :format="format"
                    :bond="getBond(i)"
                    :modelValue="getModelValue(i)"
                    @update="(...args) => atUpdate(i, ...args)"
                    @update:valid="(...args) => atUpdateValid(i, ...args)"
                    :disabled="props.disabled"
                    :ref="getRef(i)"
                />
            </div>
        </v-sheet>
    </v-card>
</template>

<script setup>
// identifier="VuetiformArray-Element"

// Dynamically add remove and sort elements
import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";

import { structuredClone } from "../../helper-functions.mjs";
import { ref, reactive, watch, nextTick, onMounted, toRaw } from "vue";
function clone(p) {
    return structuredClone(toRaw(p));
}

// the identifier helps to find the array index in subsequent leaf components.
const props = defineProps(["bond", "modelValue", "disabled", "initiaValues", "label", "panel", "identifier", "format", "namefield"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

function getBond(index) {
    if (!props.bond) return;
    const bond = props.bond || {};
    if (bond.stack && props.identifier) {
        const stack = Array.from(bond.stack);
        stack.push({ [props.identifier]: index });
        return { ...bond, stack };
    }
    return bond;
}

const data = reactive({ panel: -1, key: 0 });
//const array = reactive(clone(props.modelValue || []).map((value) => ({ value, valid: null, ref: null, key: 0 })));
const array = reactive([]);

if (props.panel !== undefined) data.panel = props.panel;

function getRef(index) {
    //Ł(index, array.length);
    return (el) => {
        if (array[index]) array[index].ref = el;
        else Ł("out of boundary");
    };
}
// -------------
function atUpdate(i, v, ...args) {
    array[i].value = v;
    //Ł(clone(v));
    updateHandler(v, ...args);

    //const r = array[i].ref;
    //if (r) if (r.isValid) array[i].valid = r.isValid();
}

function atUpdateValid(i, v, ...args) {
    array[i].valid = v;
    validHandler();
}

function getModelValue(i) {
    return array[i].value;
}

// add capability to call a function at the top on certain events to refresh the rest of the content
async function edit(action) {
    //Ł(action);
    await nextTick();
    await updateHandler(null, { component: "VuetiformArray", trigger: "edit", action, identifier: props.identifier });

    await refresh();
    //Ł(action);
}

function add() {
    const i = data.panel > -1 ? data.panel : array.length;
    const value = clone(props.initialValues || {});
    array.splice(i, 0, { value, valid: null, ref: null, key: 0 });
    data.key++;
    edit("add");
}
function remove() {
    const i = data.panel > -1 ? data.panel : array.length - 1;
    array.splice(i, 1);
    data.panel = -1;
    data.key++;
    edit("remove");
}

function moveUp() {
    const i = data.panel;
    if (!arrayMove(array, i, i - 1)) return;
    data.panel = i - 1;
    data.key++;
    edit("moveUp");
}

function moveDown() {
    const i = data.panel;
    if (!arrayMove(array, i, i + 1)) return;
    data.panel = i + 1;
    data.key++;
    edit("moveDown");
}

async function close() {
    data.panel = -1;
    edit("close");
}
function select(i) {
    if (data.panel === i) {
        edit("deselect");
        return (data.panel = -1);
    }
    edit("select");
    data.panel = i;
}

function isValid() {
    return array.map((e) => e.valid).find((e) => e !== true) || true;
}
async function updateHandler(d, ...a) {
    await nextTick();
    const datum = clone(array.map((e) => e.value));
    emit("update", datum, ...a);
    emit("update:modelValue", datum);
    validHandler();
}

let lastValidUpdate = null;
async function validHandler() {
    await nextTick();
    const valid = isValid();
    if (lastValidUpdate === valid) return;
    //Ł("valid:", valid);
    emit("update:valid", valid);
    lastValidUpdate = valid;
}

function arrayMove(arr, fromIndex, toIndex) {
    if (fromIndex < 0 || fromIndex >= arr.length || toIndex < 0 || toIndex >= arr.length) return false;
    arr.splice(toIndex, 0, arr.splice(fromIndex, 1)[0]);
    return true;
}

function refreshValues(arrayValues = []) {
    if (arrayValues.length === array.length) {
        for (const [i, x] of array.entries()) x.value = arrayValues[i];
        return;
    }
    array.length = 0;
    for (const value of arrayValues) array.push({ value, valid: null, ref: null, key: 0 });
}

async function refreshElements() {
    for (const [i, x] of array.entries()) {
        const r = array[i].ref;
        if (r) {
            if (r.refresh) await r.refresh();
            //if (r.isValid) await (array[i].valid = r.isValid());
        }
    }
}

async function refreshPanels() {
    await nextTick();
    const p = data.panel;
    // every panel must be initialized, to check validity
    for (const [i, x] of array.entries()) {
        data.panel = i;
        await nextTick();
    }
    data.panel = p;
}

async function refresh() {
    refreshValues(props.modelValue);
    await refreshPanels();
    await refreshElements();
    validHandler();
}

defineExpose({ refresh });

onMounted(async () => {
    await refresh();
});
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-array",
};
</script>

<style scoped>
.alert {
    color: red;
    font-weight: bold;
    background-color: rgba(255, 0, 0, 0.2) !important;
}
.select {
    background-color: rgba(var(--v-theme-primary), 0.3) !important;
}
.hidden {
    display: none;
}
.pointer {
    cursor: pointer;
}
</style>
