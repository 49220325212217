<template>
   <Complaint />
</template>

<script setup>
import Complaint from "@/complaint-components/Complaint.vue";

</script>

<script>
export default {
    path: "/",
    name: "user-home-page",
    icon: "mdi-home",
    sort: 10,
    root: "Home",
};
</script>

<style scoped></style>